export const catchError = async (error) => {
    try {
        if (error instanceof Response) {
            const errorRes = await error.clone().json();
            console.log('errorRes', errorRes);
            return errorRes;
        }
    }
    catch (e) {
        console.log('catchError catched ', e, 'at ', error);
    }
    return error;
};
