import * as Cheats from './Cheats';
import * as List from './List';
export const routes = [
    {
        path: '/dma-cheats',
        component: Cheats.default,
        preload: Cheats.preload,
    },
    {
        path: '/dma-components',
        component: List.default,
        preload: List.preload,
    },
];
