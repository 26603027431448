import { Link } from 'react-router-dom';
import { StatusSingletoneTooltipProvider } from 'providers';
import React, { useCallback, useRef, useState, } from 'react';
import { Image, Button, Container, GradientBorder, } from 'ui/atoms';
import { Recommendation } from 'ui/molecules';
import { useLanguage, useListener, useMobile } from 'hooks';
import { getWindowRef } from 'utils/selectors';
import { CheatStatus } from './components';
import style from './style.module.scss';
const DMAList = ({ games }) => {
    const { i18n } = useLanguage();
    const { isMobile } = useMobile();
    const wrapperRef = useRef(null);
    const [position, setPosition] = useState({
        style: { top: 0, left: 0, display: 'none' },
        data: null,
    });
    const handleMissClick = useCallback((event) => position.data
        && wrapperRef.current
        && !wrapperRef.current.contains(event.target)
        && setPosition(({ style }) => ({ style, data: null })), [position.data]);
    useListener('click', getWindowRef(), handleMissClick);
    return (React.createElement(StatusSingletoneTooltipProvider, null,
        position.data && (React.createElement("div", { ref: wrapperRef, style: position.style, className: style.popup__wrapper, onPointerLeave: () => !isMobile && setPosition(({ style }) => ({ style, data: null })) },
            React.createElement(Recommendation, { status: position.data.status, previewJPEG: position.data.previewJPEG, previewWebP: position.data.previewWebP, to: position.data.url, title: position.data.name, minPrice: position.data.minPrice, features: position.data.features, isPriceOutOfStock: !position.data.isPublished }))),
        React.createElement(Container, null,
            React.createElement("div", { className: style.grid }, games.map(({ id, name, slug, cheats, previewVerticalJPEG, previewVerticalWebP, }) => (React.createElement("div", { key: name, className: style.card },
                React.createElement("div", { className: style['card__left-column'] },
                    React.createElement(Link, { to: `/game/${slug}`, className: style.image },
                        React.createElement(Image, { alt: name, jpeg: previewVerticalJPEG, webp: previewVerticalWebP }),
                        React.createElement(Button, { theme: "outline", className: style.image__btn }, i18n.t('MORE_DETAILS'))),
                    React.createElement("div", { className: style.card__title }, name)),
                React.createElement("div", { className: style['card__right-column'] }, (cheats || []).map(cheat => (React.createElement(CheatStatus, { key: cheat.id, status: cheat.status, name: cheat.name, url: `/cheat/${slug}/${cheat.slug}`, previewJPEG: cheat.previewJPEG, previewWebP: cheat.previewWebP, isPublished: cheat.isPublished, features: cheat.features || [], minPrice: cheat.minPrice, setPosition: setPosition })))),
                React.createElement(GradientBorder, { size: "large", idPrefix: id, viewBox: "0 0 570 275" }))))))));
};
export default DMAList;
