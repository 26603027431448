import React from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from 'hooks';
import { ProductHeader } from 'ui/molecules';
import { Container, TabLink } from 'ui/atoms';
import style from './style.module.scss';
const DMATemplate = ({ children }) => {
    const { i18n } = useLanguage();
    const { pathname } = useLocation();
    const title = i18n.t(pathname === '/dma-cheats' ? 'DMA_CHEATS' : 'DMA_COMPONENTS');
    return (React.createElement(React.Fragment, null,
        React.createElement(ProductHeader, { title: title, jpeg: "", webp: "", alt: "", titleClassName: style.title }),
        React.createElement(Container, null,
            React.createElement("div", { className: style.tabs },
                React.createElement(TabLink, { to: "/dma-cheats", exact: true, className: style.tab }, i18n.t('DMA_CHEATS')),
                React.createElement(TabLink, { to: "/dma-components", exact: true, className: style.tab }, i18n.t('DMA_COMPONENTS')))),
        React.createElement("div", { className: style.content }, children)));
};
export default DMATemplate;
