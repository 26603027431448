import React from 'react';
import { observer } from 'mobx-react-lite';
import { DMATemplate } from 'ui/templates';
import { useLanguage, useStore } from 'hooks';
import { CheatsCatalog } from 'ui/organisms';
import { Helmet } from 'react-helmet-async';
const DMAComponents = () => {
    const { i18n } = useLanguage();
    const { cheatsStore } = useStore();
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, i18n.t('DMA_COMPONENTS'))),
        React.createElement(DMATemplate, null,
            React.createElement(CheatsCatalog, { cheats: cheatsStore.dmaComponentsI18n }))));
};
export const preload = ({ store }) => store.cheatsStore.fetchDMAComponents();
export default observer(DMAComponents);
