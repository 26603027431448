export const navbarLinks = [
    {
        text: 'HEADER_HOME',
        to: '/',
    },
    {
        text: 'HEADER_WARRANTY',
        to: '/warranty',
    },
    {
        text: 'HEADER_CATALOG',
        to: '/catalog',
    },
    {
        text: 'HEADER_STATUS',
        to: '/statuses',
    },
    {
        text: 'HEADER_ACCOUNTS',
        to: '/accounts',
    },
    {
        text: 'FAQ',
        to: '/faq',
    },
    {
        text: 'DMA',
        to: '/dma-cheats',
    },
    {
        text: 'HEADER_OTHER',
        subLinks: [
            {
                text: 'HEADER_PROBLEMS',
                to: '/problems',
            },
            {
                text: 'HEADER_CONTACTS',
                to: '/contacts',
            },
            {
                text: 'HEADER_FAQ',
                to: '/faq',
            },
            {
                text: 'HEADER_TERM_OF_USE',
                to: '/term-of-use',
            },
            {
                text: 'HEADER_PRIVACY_POLICY',
                to: '/privacy-policy',
            },
            {
                text: 'HEADER_PAYMENT_AND_DELIVERY',
                to: '/payment-and-delivery',
            },
            {
                text: 'HEADER_OFFER_AGREEMENT',
                to: '/offer-agreement',
            },
            {
                text: 'HEADER_PERSONAL',
                to: '/personal',
            },
        ],
    },
];
export const preventScroll = [
    ['/cheat/:gameSlug/:cheatSlug', '/cheat/:gameSlug/:cheatSlug/requirements'],
    ['/profile/techsupport', '/profile/techsupport/my', '/profile/techsupport/closed'],
    ['/profile/owner', '/profile/owner/my', '/profile/owner/all'],
    ['/profile/tickets'],
    ['/dma-cheats', '/dma-components'],
];
