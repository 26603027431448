import { action, computed, makeObservable, observable, runInAction, } from 'mobx';
import http, { createAbortController, createSearch, isAbortError } from 'utils/http';
import { catchError } from 'utils/catchError';
import { getUrlParam } from 'utils/selectors';
import { getMin } from 'utils/array';
export class CheatsStore {
    constructor(store) {
        Object.defineProperty(this, "store", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "pending", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "data", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "requirements", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "recommendations", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "error", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "statusesList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "statusesPagination", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                nextPage: null,
            }
        });
        Object.defineProperty(this, "searchPending", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "searchList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "searchPagination", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                nextPage: null,
            }
        });
        Object.defineProperty(this, "dmaCheatsPending", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "dmaCheats", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "dmaCheatsNextPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "dmaComponentsPending", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "dmaComponents", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "fetchPending", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.pending = true;
                this.error = null;
            }
        });
        Object.defineProperty(this, "fetchFailed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (error) => {
                this.pending = false;
                this.searchPending = false;
                this.error = error || null;
            }
        });
        Object.defineProperty(this, "fetchStatusesSuccessed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ list, nextPage }) => {
                this.pending = false;
                this.statusesList = list;
                this.statusesPagination = {
                    nextPage,
                };
            }
        });
        Object.defineProperty(this, "fetchSearchSuccessed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ list, nextPage }) => {
                this.searchPending = false;
                this.searchList = list;
                this.searchPagination = {
                    nextPage,
                };
            }
        });
        Object.defineProperty(this, "fetchSearchPending", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => this.searchPending = true
        });
        Object.defineProperty(this, "fetchSuccessed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (cheat) => {
                this.pending = false;
                this.data = cheat;
            }
        });
        Object.defineProperty(this, "fetchRequirementSuccessed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (requirements) => this.requirements = requirements
        });
        Object.defineProperty(this, "fetchRecommendationsSuccessed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (recommendations) => this.recommendations = recommendations
        });
        Object.defineProperty(this, "fetchStatusesList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (isLoadMore = false) => {
                const { nextPage } = this.statusesPagination;
                const allList = this.statusesList;
                if (allList.length && (!isLoadMore || nextPage === null))
                    return;
                this.fetchPending();
                try {
                    const search = createSearch({
                        page: nextPage && isLoadMore ? nextPage : 1,
                    });
                    const res = await http.get(`/games/statuses${search}`);
                    this.fetchStatusesSuccessed({
                        list: isLoadMore
                            ? [...allList, ...res.list]
                            : res.list,
                        nextPage: res.nextPage,
                    });
                }
                catch (e) {
                    const error = await catchError(e);
                    this.fetchFailed(error === null || error === void 0 ? void 0 : error.message);
                }
            }
        });
        Object.defineProperty(this, "abortSearch", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: createAbortController()
        });
        Object.defineProperty(this, "searchStatusesList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (isLoadMore = false) => {
                this.abortSearch.initAbort();
                this.fetchSearchPending();
                const search = getUrlParam(this.store.routingStore.location.search, 'search');
                const searchList = this.searchList;
                const { nextPage } = this.searchPagination;
                try {
                    const searchQuery = createSearch({
                        page: nextPage && isLoadMore ? nextPage : 1,
                        search,
                    });
                    const res = await http.get(`/games/statuses${searchQuery}`, this.abortSearch.getSignal());
                    this.fetchSearchSuccessed({
                        list: isLoadMore
                            ? [...searchList, ...res.list]
                            : res.list,
                        nextPage: res.nextPage,
                    });
                }
                catch (e) {
                    if (isAbortError(e))
                        return;
                    const error = await catchError(e);
                    this.fetchFailed(error === null || error === void 0 ? void 0 : error.message);
                }
            }
        });
        Object.defineProperty(this, "fetchCheat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (gameSlug, cheatSlug) => {
                var _a, _b;
                if (((_a = this.data) === null || _a === void 0 ? void 0 : _a.slug) === cheatSlug && ((_b = this.data.game) === null || _b === void 0 ? void 0 : _b.slug) === gameSlug)
                    return;
                this.fetchPending();
                try {
                    const { cheat } = await http.get(`/cheats/slugs/${gameSlug}/${cheatSlug}`);
                    this.fetchSuccessed(cheat);
                }
                catch (e) {
                    if (e.status === 404) {
                        this.store.setStatus(404);
                    }
                    const error = await catchError(e);
                    this.fetchFailed(error === null || error === void 0 ? void 0 : error.message);
                }
            }
        });
        Object.defineProperty(this, "fetchRequirements", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (gameSlug, cheatSlug) => {
                var _a, _b;
                if (((_a = this.requirements) === null || _a === void 0 ? void 0 : _a.slug) === cheatSlug && ((_b = this.requirements.game) === null || _b === void 0 ? void 0 : _b.slug) === gameSlug)
                    return;
                this.fetchPending();
                try {
                    const res = await http.get(`/cheats/requirements/${gameSlug}/${cheatSlug}`);
                    this.fetchRequirementSuccessed(res);
                }
                catch (e) {
                    if (e.status === 404) {
                        this.store.setStatus(404);
                    }
                    const error = await catchError(e);
                    this.fetchFailed(error === null || error === void 0 ? void 0 : error.message);
                }
            }
        });
        Object.defineProperty(this, "fetchRecommendations", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (gameSlug) => {
                var _a;
                if (((_a = this.recommendations) === null || _a === void 0 ? void 0 : _a.game.slug) === gameSlug)
                    return;
                this.fetchPending();
                try {
                    const res = await http.get(`/games/recommendations/${gameSlug}`);
                    this.fetchRecommendationsSuccessed(res);
                }
                catch (e) {
                    const error = await catchError(e);
                    this.fetchFailed(error === null || error === void 0 ? void 0 : error.message);
                }
            }
        });
        Object.defineProperty(this, "fetchDMACheats", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (isLoadMore = false) => {
                const allList = this.dmaCheats;
                if ((allList.length !== 0 && this.dmaCheatsNextPage === null) || this.dmaCheatsPending)
                    return;
                this.dmaCheatsPending = true;
                try {
                    const res = await http.get(`/cheats/dma-cheats?page=${this.dmaCheatsNextPage || 1}`);
                    runInAction(() => {
                        this.dmaCheats = isLoadMore ? [...allList, ...res.list] : res.list;
                        this.dmaCheatsNextPage = res.nextPage;
                        this.dmaCheatsPending = false;
                    });
                }
                catch (e) {
                    const error = await catchError(e);
                    this.fetchFailed(error === null || error === void 0 ? void 0 : error.message);
                    runInAction(() => this.dmaCheatsPending = false);
                }
            }
        });
        Object.defineProperty(this, "fetchDMAComponents", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                if (this.dmaComponents.length || this.dmaComponentsPending)
                    return;
                this.dmaComponentsPending = true;
                try {
                    const res = await http.get('/cheats/dma-components');
                    runInAction(() => {
                        this.dmaComponents = res.list;
                        this.dmaComponentsPending = false;
                    });
                }
                catch (e) {
                    const error = await catchError(e);
                    this.fetchFailed(error === null || error === void 0 ? void 0 : error.message);
                    runInAction(() => this.dmaComponentsPending = false);
                }
            }
        });
        this.store = store;
        makeObservable(this, {
            pending: observable,
            data: observable,
            error: observable,
            statusesList: observable,
            statusesPagination: observable,
            searchPending: observable,
            searchList: observable,
            searchPagination: observable,
            requirements: observable,
            recommendations: observable,
            dmaCheats: observable,
            dmaCheatsNextPage: observable,
            dmaCheatsPending: observable,
            dmaComponentsPending: observable,
            dmaComponents: observable,
            dmaCheatsI18n: computed,
            dmaComponentsI18n: computed,
            selectData: computed,
            selectRequirements: computed,
            selectRecommendations: computed,
            fetchPending: action.bound,
            fetchFailed: action.bound,
            fetchStatusesSuccessed: action.bound,
            fetchSearchSuccessed: action.bound,
            fetchSearchPending: action.bound,
            fetchSuccessed: action.bound,
            fetchRequirementSuccessed: action.bound,
            fetchRecommendationsSuccessed: action.bound,
            fetchStatusesList: action,
            searchStatusesList: action,
            fetchCheat: action,
            fetchRequirements: action,
            fetchRecommendations: action,
            fetchDMAComponents: action,
            fetchDMACheats: action,
        });
    }
    get dmaCheatsI18n() {
        const lang = this.store.commonStore.language;
        return this.dmaCheats.map(game => (Object.assign(Object.assign({}, game), { cheats: (game.cheats || [])
                .map(CheatsStore.makeCheatI18n(lang))
                .sort(CheatsStore.sortCheats) })));
    }
    get dmaComponentsI18n() {
        const lang = this.store.commonStore.language;
        return this.dmaComponents
            .map(CheatsStore.makeCheatI18n(lang))
            .sort(CheatsStore.sortCheats);
    }
    get selectData() {
        if (!this.data)
            return this.data;
        const language = this.store.commonStore.language;
        return Object.assign(Object.assign({}, this.data), { localization: this.data.localization[language], videos: this.data.videos[language] });
    }
    get selectRequirements() {
        if (!this.requirements)
            return this.requirements;
        const language = this.store.commonStore.language;
        return {
            requirements: this.requirements.requirements[language],
            features: this.requirements.features.map(feature => { var _a; return (_a = feature.localization[language]) === null || _a === void 0 ? void 0 : _a.name; })
                .filter(Boolean)
                .splice(0, 4),
        };
    }
    get selectRecommendations() {
        if (!this.recommendations)
            return this.recommendations;
        const language = this.store.commonStore.language;
        return this.recommendations.recommendations
            .filter(({ slug }) => slug !== this.data.slug)
            .map(CheatsStore.makeCheatI18n(language))
            .sort(CheatsStore.sortCheats);
    }
}
Object.defineProperty(CheatsStore, "sortCheats", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (prev, next) => {
        var _a, _b, _c, _d;
        const prevMinPrice = getMin((_a = prev.minPrice) === null || _a === void 0 ? void 0 : _a.price, (_b = prev.minPrice) === null || _b === void 0 ? void 0 : _b.discountPrice);
        const nextMinPrice = getMin((_c = next.minPrice) === null || _c === void 0 ? void 0 : _c.price, (_d = next.minPrice) === null || _d === void 0 ? void 0 : _d.discountPrice);
        if (!prevMinPrice || !prev.isPublished) {
            return 1;
        }
        if (!nextMinPrice || !next.isPublished) {
            return -1;
        }
        return prevMinPrice - nextMinPrice;
    }
});
Object.defineProperty(CheatsStore, "makeCheatI18n", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (lang) => (cheat) => {
        var _a;
        return (Object.assign(Object.assign({}, cheat), { features: (_a = cheat.features) === null || _a === void 0 ? void 0 : _a.map(feature => feature.localization[lang].name).filter(Boolean), minPrice: cheat.minPrice[lang], localization: undefined, videos: undefined }));
    }
});
