import React from 'react';
import { Link } from 'react-router-dom';
import { StatusLazyTooltip } from 'ui/molecules';
import { useMobile } from 'hooks';
import style from './style.module.scss';
export const CheatStatus = ({ name, status, url, previewJPEG, previewWebP, isPublished, features, minPrice, setPosition }) => {
    const { isMobile } = useMobile();
    return (React.createElement(Link, { to: url, className: style.status__wrapper, onPointerEnter: e => {
            const bodyRect = document.body.getBoundingClientRect(), elemRect = e.currentTarget.getBoundingClientRect(), offset = elemRect.top - bodyRect.top;
            setPosition({
                style: {
                    top: offset + 14,
                    left: isMobile ? 40 : elemRect.left,
                    display: 'flex',
                },
                data: {
                    status,
                    previewJPEG,
                    previewWebP,
                    url,
                    name,
                    minPrice,
                    features,
                    isPublished,
                },
            });
        } },
        React.createElement(StatusLazyTooltip, { status: status, id: url }),
        React.createElement("div", { className: style.status__title }, name)));
};
