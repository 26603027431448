import config from 'config';
import { noop } from './functions';
import { isBrowser } from './selectors';
const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};
export const createClient = ({ baseURL, getHeaders, }) => {
    const formatBody = (body, options = {}) => {
        const headers = Object.assign({}, defaultHeaders);
        const isForm = body instanceof FormData;
        if (isForm) {
            delete headers['Content-Type'];
        }
        return Object.assign(Object.assign({}, options), { headers: Object.assign(Object.assign(Object.assign({}, headers), getHeaders()), options === null || options === void 0 ? void 0 : options.headers), body: isForm
                ? body
                : JSON.stringify(body) });
    };
    const xmlRequest = (method, url, options = {}) => new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.upload.onprogress = event => {
            const percent = event.total / 100;
            const progress = Math.round(event.loaded / percent);
            options.proggress(progress);
        };
        xhr.open(method, url);
        if (options.headers) {
            for (const [header, value] of Object.entries(options.headers)) {
                xhr.setRequestHeader(header, value);
            }
        }
        xhr.onload = function () {
            if (this.status >= 200 && this.status <= 299) {
                resolve(JSON.parse(xhr.response));
            }
            else {
                reject({
                    status: this.status,
                    statusText: xhr.statusText,
                });
            }
        };
        xhr.onerror = function () {
            reject({
                status: this.status,
                statusText: xhr.statusText,
            });
        };
        xhr.send(options.body);
    });
    const request = async (method, url, options = {}) => {
        const fullUrl = /^http/i.test(url) ? url : `${baseURL}${url}`;
        if (options.proggress)
            return await xmlRequest(method, fullUrl, options);
        const response = await fetch(fullUrl, Object.assign(Object.assign({}, options), { method }));
        if (response.ok) {
            return await response.json();
        }
        throw response;
    };
    const get = (url, options) => {
        const optionsCopy = Object.assign({}, options);
        optionsCopy.headers = Object.assign(Object.assign(Object.assign({}, defaultHeaders), getHeaders()), optionsCopy.headers);
        return request('GET', url, optionsCopy);
    };
    const post = async (url, body, options) => {
        const fullOptions = formatBody(body, options);
        return await request('POST', url, fullOptions);
    };
    const put = async (url, body, options) => {
        const fullOptions = formatBody(body, options);
        return await request('PUT', url, fullOptions);
    };
    const patch = async (url, body, options) => {
        const fullOptions = formatBody(body, options);
        return await request('PATCH', url, fullOptions);
    };
    const del = async (url, body, options) => {
        const fullOptions = formatBody(body, options);
        return await request('DELETE', url, fullOptions);
    };
    return {
        get,
        post,
        put,
        patch,
        delete: del,
    };
};
export default createClient({
    baseURL: config.apiUrl,
    getHeaders: () => ({}),
});
export const createSearch = (data) => {
    const params = Object.entries(data).filter(([, value]) => typeof value !== 'undefined'
        && value !== null
        && value !== false)
        .map(([key, value]) => ([key, `${value}`]));
    const search = new URLSearchParams(Object.fromEntries(params));
    return search.toString() !== ''
        ? `?${search}`
        : '';
};
export const isAbortError = (error) => error instanceof DOMException;
export const createAbortController = () => {
    if (!isBrowser) {
        return {
            getSignal: () => ({
                signal: undefined,
            }),
            initAbort: noop,
        };
    }
    let controller;
    const initAbort = () => {
        if (controller && !controller.signal.aborted) {
            controller.abort();
        }
        controller = new AbortController();
    };
    return {
        getSignal: () => ({
            signal: controller.signal,
        }),
        initAbort,
    };
};
