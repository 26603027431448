const configs = {
    development: {
        apiUrl: 'http://localhost:3081',
        googleAnalyticsId: '',
        yandexMetrikaId: 0,
        chatBro: false,
        vapidKey: '',
    },
    production: {
        apiUrl: 'https://elitehacks.ru/api',
        googleAnalyticsId: 'UA-112864276-40',
        yandexMetrikaId: 64371709,
        chatBro: true,
        vapidKey: 'BGTB7f4-ROAKKhsQqF3xIegcx1Yxhmb9trDAbXTFRZt-na_NUPSS7U76FIW1w3oekwMPy97Why0Sjfk4U2RU9aY',
        redisPass: 'eliteredis',
    },
};
const config = configs[process.env.NODE_ENV];
if (!config) {
    throw new Error(`Config for env "${process.env.NODE_ENV}" not found`);
}
console.log('Loaded config: ', config);
export default config;
