import React from 'react';
import { observer } from 'mobx-react-lite';
import { DMATemplate } from 'ui/templates';
import { DMAList } from 'ui/organisms';
import { useLanguage, useStore } from 'hooks';
import { Helmet } from 'react-helmet-async';
const DMACheats = () => {
    const { i18n } = useLanguage();
    const { cheatsStore } = useStore();
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, i18n.t('DMA_CHEATS'))),
        React.createElement(DMATemplate, null,
            React.createElement(DMAList, { games: cheatsStore.dmaCheatsI18n, isLoading: cheatsStore.dmaCheatsPending, isShowLoadMore: !!cheatsStore.dmaCheatsNextPage, loadMoreAction: () => cheatsStore.fetchDMACheats(true) }))));
};
export const preload = ({ store }) => store.cheatsStore.fetchDMACheats();
export default observer(DMACheats);
