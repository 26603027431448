import { defineRoutes } from 'libs/router';
import { AppTemplate } from 'ui/templates';
import * as Home from 'containers/Home';
import { routes as accountRoutes } from 'containers/Accounts/routes';
import { routes as aboutUsRoutes } from 'containers/AboutUs/routes';
import { routes as profileRoutes } from 'containers/Profile/routes';
import { routes as cheatRoutes } from 'containers/Cheats/routes';
import { routes as gameRoutes } from 'containers/Games/routes';
import { routes as problemRoutes } from 'containers/Problems/routes';
import { routes as dmaRoutes } from 'containers/DMA/routes';
import NotFound from 'containers/NotFound';
export const routes = defineRoutes([
    {
        path: '/:lang(en)?',
        component: AppTemplate,
        preload: ({ store }) => Promise.all([
            store.commonStore.fetchSettignsList(),
            store.authStore.profile(),
        ]),
        routes: [
            {
                path: '',
                exact: true,
                component: Home.default,
                preload: Home.preload,
            },
            ...dmaRoutes,
            ...gameRoutes,
            ...cheatRoutes,
            ...accountRoutes,
            ...problemRoutes,
            ...profileRoutes,
            ...aboutUsRoutes,
            {
                component: NotFound,
            },
        ],
    },
]);
